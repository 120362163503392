.competence__container{
    width: 450px;
    height: 280px;
    background-color: rgba(217,217,217,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 10px;
}

.competence__container img{
    height: 110px;
}

.competence__container h3{
    color: rgba(228,228,228);
    font-size: 32px;
    font-weight: 600;
    font-family: "Inter", serif;
}

.competence__level{
    display: flex;
    font-family: "Inter", serif;
    font-size: 18px;
    font-weight: 600;
    gap: 20px;
    position: relative;
}

.competence__level span{
    width: 220px;
    height: 20px;
    background-color: #2B2B2B;
    border-radius: 20px;
    display: block;
}

.competence__debutant p{
    color: #3DFF44;
}

.competence__intermediaire p{
    color: #e76a1d;
}

.competence__avance p {
    color: #b72222;
}

.competence__debutant:before,
.competence__intermediaire:before,
.competence__avance:before{
    position: absolute;
    bottom: 0;
    content: ' ';
    height: 100%;
    border-radius: 20px;
}

.competence__debutant:before{
    background-color: #3DFF44;
    width: 25%;
}

.competence__intermediaire:before{
    background-color: #e76a1d;
    width: 40%;
}

.competence__avance:before{
    background-color: #b72222;
    width: 65%;
}

@media screen and (max-width: 800px) {

    .competence__avance:before,
    .competence__intermediaire:before,
    .competence__debutant:before{
        height: 10px;
        top: 0;
        left: 10%;
    }

    .competence__container span{
        width: 80%;
        height: 10px;
    }


    .competence__container{
        width: 200px;
        height: 220px;
        gap: 10px;
        border-radius: 0 0px 0px 0;
    }

    .competence__container:nth-child(n+9){
        display: none;
    }

    .competence__container img{
        width: 75px;
        height: auto;
    }

    .competence__container .competence__level{
        font-size: 24px;
        flex-direction: column;
        width: 80%;
        align-items: center;
    }

    .competence__container:nth-child(2){
        border-radius: 0 10px 0 0;
    }

    .competence__container:nth-child(1){
        border-radius: 10px 0 0 0;
    }

    .competence__container p{
        font-size: 18px;
    }
}

@media screen  and (max-width:500px){
    .competence__container{
        width: 125px;
        height: 125px;
    }

    .competence__container img{
        width: 40px;
        height: auto;
    }

    .competence__container h3{
        font-size: 18px;
    }

    .competence__container p{
        display: none;
    }
}


