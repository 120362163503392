* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    scroll-behavior: smooth;
}

body{
    background-color: var(--background-color);
    overflow-x: hidden;
}

html{
    overflow-x: hidden;
}

