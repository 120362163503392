
.presentation__content h1,
.presentation__content h2,
.presentation__content p{
    font-family: var(--default-font), serif;
}

.presentation__content{
    width: 567px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.presentation__heading {
    color: #E4E4E4;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.presentation__sub-heading{
    color: rgba(255, 216, 139, 0.75);
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.presentation__text{
    color: rgba(228, 228, 228, 0.70);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 17px;
}

.presentation__link-list{
    display: flex;
    gap: 30px;
    margin-top: 64px;
}

.presentation__links__icons{
    width: 40px;
    height: 40px;
    transition: all 0.2s;
}

.presentation__links__icons:hover{
    transform: scale(1.1);
}

@media screen and (max-width: 1350px){
    .presentation__heading{
        font-size: 60px;
    }

    .presentation__link-list{
        justify-content: center;
        margin-top: 30px;
    }

    .presentation__content{
        width: 70% !important;
        align-items: center;
        text-align: center;
    }

}

@media screen and (max-width: 990px) {
    .presentation__content{
        width: 70% !important;
        align-items: center;
        text-align: center;
    }

    .presentation__link-list{
        margin-top: 20px;
    }

    .presentation__heading{
        font-size: 40px;
    }

    .presentation__sub-heading{
        font-size: 22px;
    }

    .presentation__text{
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .presentation__content{
        width: 95% !important;
    }

}
