.parcours__steps-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 44vw;
    align-items: center;
    width: fit-content;
    min-width: 50vw;
    height: 100vh;
    gap: 50px;
}

#parcours header{
    width: 80%;
}

.parcours__title{
    font-family: "Inter", serif;
    font-size: 48px;
    font-weight: bold;
    color: rgba(228,228,228);
    text-align: center;
}

.parcours__subtitle{
    text-align: center;
    font-size: 20px;
    color: rgba(228,228,228,0.6);
    font-family: "Inter", serif;
    margin-top: 20px;
}

@media screen and (max-width: 1350px) {
    .parcours__steps-container{
        margin: 0 auto;
        gap: 70px;
        height: fit-content;
        align-items: center;
        padding: 150px 0 ;
    }
}

@media screen and (max-width: 600px) {
    .parcours__steps-container p{
        font-size: 16px;
    }

     #parcours header h2{
         font-size: 40px;
     }

     #parcours header p{
         font-size: 14px;
     }
}