.hero__section{
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10rem;
}

.hero__profile-image{
    height: fit-content;
    width: 450px;
    margin-right: 15vw;
    margin-bottom: 15vh;
}

.hero__section__left-content{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 47vh;
    transform: translate(-50%, -50%);
    left: 26vw;
}



@media screen and (max-width: 1350px) {
    .hero__profile-image{
        width: 450px;
        height: 450px;
        margin-bottom: 25vh;
        margin-right: 5vw;
    }

    .hero__section{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 2rem;
        height: fit-content;
        align-items: center;
    }

    .hero__section__left-content .presentation__content{
        width: 475px;
    }

    .hero__section__left-content{
        position: static !important;
    }

    .hero__profile-image{
        margin: 2rem 0 0 0;
        width: 300px;
        height: auto;
    }


    .hero__section__left-content{
        display: flex;
        transform: none;
        width: 90vw;
        align-items: center;
        padding-top: 2rem;
    }
}


@media screen and (max-width: 1100px) {
    .hero__profile-image{
        width: 375px;
        height: 375px;
    }
}

@media screen and (max-width: 990px) {

    .hero__profile-image{
        margin: 2rem 0 0 0;
        width: 250px;
        height: auto;
    }


}






