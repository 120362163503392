.navbar__title{
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.50);
    font-family: Inter, serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1.26px;
    width: fit-content;
    transition: all 0.15s ease-out;
}

.navbar__link-list span{
    content: ' ';
    display: block;
    width: 75px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.50);
    transition: all 0.15s ease-out;
}

.navbar__link-list > div{
    display: flex;
    align-items: center;
    gap: 15px;
}

.navbar__link-list{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.navbar-element-container{
    width: fit-content;
    transition: all 0.2s ease-in;
    padding: 0.5rem 1rem;
    padding-left: 0;
    position: relative;

}

.navbar-element-container:hover{
    cursor: pointer;
}

.navbar-element-container:hover span{
    background-color: #E4E4E4;
    width: 90px;
    height: 4px;
}

.navbar-element-container:hover h3{
    color: #E4E4E4;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0px 3px 5px rgba(218,218,218,0.3);
}

.navbar__link-list a{
    position: absolute;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1350px){
    .navbar__link-list span{
        display: none;
    }

    .navbar__link-list{
        flex-direction: row;
        margin-top: 3rem;
        justify-content: center;
    }

    .navbar-element-container{
        justify-content: center;
    }

    .navbar__link-list h3{
        font-size: 22px;
    }
}

@media screen and (max-width: 600px){
    .navbar__link-list{
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .navbar-element-container{
        justify-content: center;
        padding: 0 5%;
    }

    .navbar-element-container h3{
        font-size: 26px;
    }
}