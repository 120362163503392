.parcours__step__grid{
    display: grid;
    grid-template-rows: 35px fit-content(100%);
    grid-template-columns: 225px 425px;
    font-family: var(--default-font), serif;
    transition: all .2s;
    padding: 1rem 1.5rem;
    border-radius: 10px;
}

.parcours__step__grid:hover{
    background-color: rgba(288,288,228,0.15);
    transform: scale(1.1);
    cursor: pointer;
}

.parcours__step__grid h2{
    grid-row: 1;
    grid-column: 2;
    font-weight: 600;
    color: rgba(255,216,139,0.8);
    font-size: 20px;
}

.parcours__step__grid h3{
    grid-row: 1;
    grid-column: 1;
    color: rgba(228,228,228,0.6);
    font-weight: 500;
    font-size: 16px;
    position: relative;
}

.parcours__step__grid h3:after{
    content: ' ';
    position: absolute;
    bottom: 15%;
    left: 0;
    height: 3px;
    width: 45px;
    background-color: rgba(255,216,139,0.8);
}

.parcours__step__grid p{
    grid-row: 2;
    grid-column: 2;
    color: rgba(228,228,228,0.6);
    font-size: 16px;
    width: 100%;
}

@media screen and (max-width: 1100px) {

    .parcours__step__grid{
        display: flex;
        flex-direction: column;
        width: 70vw;
        align-items: center;
    }


    .parcours__step__grid h3{
        margin-top: 10px;
        font-size: 19px;
    }

    .parcours__step__grid h2{
        font-size: 32px;
        text-align: center;
    }

    .parcours__step__grid h3:after{
        display: none;
    }

    .parcours__step__grid p{
        margin-top: 25px;
        font-size: 19px;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .parcours__step__grid h2{
        font-size: 24px;
    }

    .parcours__step__grid{
        width: 85vw;
    }

    .parcours__step__grid:hover{
        transform: scale(1);
    }
}
