@keyframes scroll {
    0%{
        transform: translate(0%);
    }
    100%{
        transform: translate(-50%);
    }
    100.01%{
        transform: translate(0);
    }
}

.competences-caroussel{
    display: flex;
    gap: 45px;
    width: fit-content;
    animation: scroll 20s linear infinite -1s;
    align-self: flex-start;
}

@media screen and (max-width: 800px){
    .competences-caroussel{
        display: grid;
        grid-template-columns: repeat(auto-fit,200px);
        grid-gap: 5px;
        animation: none;
        width: 90vw;
        justify-content: center;
        margin: 0 auto;
    }


}

@media screen and (max-width:500px){
    .competences-caroussel{
        grid-template-columns: repeat(auto-fit,125px);
    }
}